import { Layout, Spin } from 'antd'
import MountTransition from 'components/animation/MountTransition'
import { AnimatePresence } from 'framer-motion'
import { RootState } from 'globalStore'
import React, { Suspense } from 'react'
import { useSelector } from 'react-redux'
import { Navigate, useLocation, useOutlet } from 'react-router-dom'
import { siteAuthConfig } from 'router/siteConfig'
import Narbar from './Narbar'

const MasterLayout: React.FC = () => {
  const session = useSelector((state: RootState) => state.auth.session)
  const location = useLocation()
  const outlet = useOutlet()
  const [child, setChild] = React.useState(outlet)

  if (!session) {
    return <Navigate to={siteAuthConfig.routes.login.path} />
  }

  return (
    <Layout>
      <Layout.Header style={{ width: '100%', padding: 0 }}>
        <Narbar />
      </Layout.Header>
      <Layout.Content
        className='site-layout'
        style={{ height: 'calc(100vh - 60px)', background: '#f0f2f5', padding: 6, border: '1px solid #f0f0f0' }}
      >
        <Suspense fallback={<Spin />}>
          <AnimatePresence
            // mode='wait'
            exitBeforeEnter
            onExitComplete={() => setChild(outlet)}
          >
            <MountTransition key={location.pathname}>{child}</MountTransition>
          </AnimatePresence>
        </Suspense>
      </Layout.Content>
    </Layout>
  )
}

export default MasterLayout
