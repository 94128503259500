import { message } from 'antd'
import { AxiosInstance } from 'axios'
import { setup, setupCache } from 'axios-cache-adapter'
import { store } from 'globalStore'
import { authActions } from 'globalStore/auth/slice'

const baseURL = window.__RUNTIME_CONFIG__.API_GATEWAY_URL

export const cache = setupCache({
  maxAge: 2 * 60 * 1000,
  exclude: {
    query: false,
    methods: ['post', 'patch', 'put', 'delete']
  }
})

const fetch: AxiosInstance = setup({
  baseURL,
  cache,
  timeout: 10 * 1000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  withCredentials: true
})

fetch.interceptors.response.use(
  response => response.data,
  error => {
    if (error.message === 'Network Error') {
      //   message.error(error.message)
      return Promise.reject(error)
    }

    if (error?.response?.status === 404) {
      return Promise.reject(new Error(error.message))
    }

    if (error?.response?.status === 401) {
      const isAuthenticated = store.getState().auth.session
      if (isAuthenticated) {
        store.dispatch(authActions.logout())
        message.error('Session is exprired')
      }

      return Promise.reject(error)
    }

    if (error?.response?.status === 403) {
      message.error(error.response.data.message)
      return Promise.reject(error)
    }

    const wrapError = error?.response?.data || error

    return Promise.reject(wrapError)
  }
)

export function setToken(token: string) {
  fetch.defaults.headers.common['Authorization'] = `Bearer ${token}`
}
export function clearToken() {
  fetch.defaults.headers.common['Authorization'] = ''
}

export default fetch
