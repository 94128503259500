import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { siteAuthConfig, routerConfig } from './siteConfig'

import AuthLayout from 'layouts/AuthLayout'
import MasterLayout from 'layouts/MasterLayout'
import NavigateAuthCallback from './NavigateAuthCallback'
import React, { Suspense } from 'react'
const SignaturePage = React.lazy(() => import('pages/SignaturePage'))

const generateRoutes = (props: any) => {
  return (
    <Route key={props.path} path={props.path} index={props.index} element={props.component}>
      {props.routes &&
        Object.keys(props.routes).map(key => {
          const item = props.routes[key]
          return generateRoutes(item)
        })}
    </Route>
  )
}
const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='callback' element={<NavigateAuthCallback />} />
        <Route
          path='signature/:uuid'
          index
          element={
            <Suspense fallback={null}>
              <SignaturePage />
            </Suspense>
          }
        />

        <Route path={siteAuthConfig.path} element={<AuthLayout />}>
          {Object.keys(siteAuthConfig.routes).map((key: any) => {
            // @ts-ignore
            const item = siteAuthConfig.routes[key]

            return (
              <Route key={item.path.replace(siteAuthConfig.path, '')} path={item.path} element={<item.component />} />
            )
          })}
        </Route>

        <Route path='' element={<MasterLayout />}>
          {/* {Object.keys(routerConfig).map(key => {
            // @ts-ignore
            const item = routerConfig[key]
            return generateRoutes(item)
          })} */}
          {generateRoutes(routerConfig['home'])}
        </Route>

        <Route path='*' element={<Navigate to='/' replace />} />
      </Routes>
    </BrowserRouter>
  )
}

export default Router
