import { Avatar, Dropdown, Menu } from 'antd'
import logo from 'assets/icon/certis_logo.png'
import { useLocation, useNavigate } from 'react-router-dom'
import { routerPathConfig } from 'router/siteConfig'
import { LogoutOutlined, UserOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import { authActions } from 'globalStore/auth/slice'
import { RootState } from 'globalStore'

const Narbar: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const session = useSelector((state: RootState) => state.auth.session)

  return (
    <div style={{ display: 'flex', alignItems: 'center', background: '#fff', padding: '0 8px' }}>
      <div className='centered-content' style={{ width: 150 }}>
        <Avatar src={logo} style={{ width: 200, height: 45 }} size={45} shape='square' />
      </div>
      <Menu
        mode='horizontal'
        style={{ flex: 1 }}
        defaultSelectedKeys={[location.pathname]}
        items={[
          { key: routerPathConfig.home.path, label: 'Tracking' }
          // { key: routerPathConfig.user.path, label: 'User' }
        ]}
        onClick={e => {
          navigate(e.key)
        }}
      />
      <Dropdown
        overlay={
          <Menu
            items={[
              {
                key: 'profile',
                label: `${session?.firstName} ${session?.lastName}`
              },
              {
                key: 'logout',
                label: 'Logout',
                icon: <LogoutOutlined />,
                onClick: () => dispatch(authActions.logout())
              }
            ]}
          />
        }
      >
        <Avatar style={{ cursor: 'pointer' }} icon={<UserOutlined style={{ fontSize: 18 }} />} />
      </Dropdown>
    </div>
  )
}

export default Narbar
