import React from 'react'
import path from 'path-browserify'

//auth-home
const LoginPage = React.lazy(() => import('pages/Auth/LoginPage'))
const CallbackPage = React.lazy(() => import('pages/Auth/CallbackPage'))

// Tracking
const TrackingPage = React.lazy(() => import('pages/TrackingPage'))

// User
const UserPage = React.lazy(() => import('pages/User/UserListPage'))
const UserCreatePage = React.lazy(() => import('pages/User/UserCreatePage'))
const UserEditPage = React.lazy(() => import('pages/User/UserEditPage'))

export const siteAuthConfig = {
  path: '/auth',
  routes: {
    login: {
      path: '/auth/login',
      name: 'Login',
      component: LoginPage
    },
    callback: {
      path: '/auth/callback',
      name: 'Callback',
      component: CallbackPage
    }
  }
}

export interface IRouteConfig {
  path: string
  index?: boolean
  component?: JSX.Element | React.FC<{}>
  routes?: {
    [key: string]: IRouteConfig
  }
}

export const routerConfig = {
  home: {
    path: '',
    index: true,
    component: <TrackingPage />
  },
  user: {
    path: 'user',
    routes: {
      list: {
        path: '',
        index: true,
        component: <UserPage />
      },
      create: {
        path: 'create',
        component: <UserCreatePage />
      },
      edit: {
        path: ':username',
        component: <UserEditPage />
      }
    }
  }
}

export function mergePathClone(obj: any, parentPath: string = '/') {
  const result: any = {}
  for (var key in obj) {
    const routeItem = obj[key]

    const pathNow = path.join(parentPath, routeItem.path).replaceAll('.', '')
    result[key] = {
      path: pathNow
    }

    if (routeItem.routes) {
      result[key].routes = mergePathClone(routeItem.routes, pathNow)
    }
  }

  return result
}

export const routerPathConfig = mergePathClone(routerConfig, '/') as typeof routerConfig
