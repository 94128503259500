import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { ActionLogin, AuthState, ISession } from './types'
import { clearToken } from 'api/fetch'

const initialState = {
  // token: null,
  // isAuthenticated: false,
  session: null
} as AuthState

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout(state) {
      // state.token = null
      // state.isAuthenticated = false
      state.session = null
      clearToken()
    },
    login(state, action: PayloadAction<ActionLogin>) {
      const { session } = action.payload
      // state.token = token
      // state.isAuthenticated = true
      state.session = session
      // setToken(token)
    },
    updateSession(state, action: PayloadAction<{ session: ISession }>) {
      state.session = action.payload.session
    }
  }
})

export const authActions = authSlice.actions
